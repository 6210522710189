.photo-slider {
  height: 75%;
  display: flex;
  max-width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  scrollbar-width: none;
  /* Hide scrollbar in Firefox */
  -ms-overflow-style: none;
  /* Hide scrollbar in Internet Explorer/Edge */
  align-items: flex-end;
}

.photo-slider::-webkit-scrollbar {
  display: none;
  /* Hide scrollbar in Chrome/Safari/Opera */
}

.photo-slide {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  margin-right: 10px;
  cursor: pointer;
  opacity: 0.05;
  transition: opacity 0.5s;
  height: 100%;
  justify-content: flex-end;
}

.photo-slide.selected {
  opacity: 1;
}

.photo-slide:hover {
  opacity: 1;
}

.photo-slide:last-child {
  margin-right: 0;
}

.swiper {
  width: 100%;
  height: 100%;
}
