@font-face {
  font-family: abcrom;
  src: url('/public/ABCROM-Book-Trial.otf');
}
p {
  white-space: pre-wrap;
  line-height: 1.1rem; 
}
body {
  letter-spacing: 0.3px;
  font-size: 0.95rem;
  margin: 0;
  font-family:'abcrom', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

@media (max-width: 1000px) {
  body {
    font-size: 0.85rem;
  }
}
* {

  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
