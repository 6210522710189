.slide {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
}

.swiper {
  width: 100%;
  height: fit-content;
}